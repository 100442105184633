<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="row col-12 m-0 p-0 align-items-center">
          <div class="my-auto mr-2">
            <h4 class="card-title my-auto">Liste des capacités SI</h4>
          </div>
          <DropDownCustom
            :items="['Tableau', 'Graphique']"
            @changeValue="onValueChange"
          />
          <div
            class="d-flex justify-content-end justify-content-center flex-wrap col-sm-12 col-md-auto p-0 ml-auto mr-2"
          >
            <b-form-group
              class="ml-2 col-sm-12 col-md-auto p-0 mb-0 my-1"
              label-for="input1"
            >
              <b-form-input
                v-model="searchQuery.name"
                type="text"
                placeholder="Nom"
              ></b-form-input>
            </b-form-group>
          </div>
          <CustomAddButton
            @click="$router.push({ name: 'add-infosys-capacity' })"
            text="Créer capacité SI"
          />
        </div>
      </div>
      <hr class="mt-0 bg-secondary" />

      <VisionTable
        v-if="modeShow == 0"
        @editItem="$router.push({ name: 'edit-infosys-capacity' })"
        @showItem="$router.push({ name: 'show-infosys-capacity' })"
        @showGoals="showGoalsFunction"
        @showSousCapacite="showSousCapaciteFunction"
        class="mt-3"
        :fields="tableFields"
        :items="filteredCapacites"
        :capacites="filteredCapacites"
        :loading="loadingData"
      />

      <GraphicView :capacites="filteredCapacites" class="mt-3" v-else />
    </section>

    <div v-if="showGoals" to="modals-xyz-548">
      <Modal @close="showGoals = !showGoals">
        <goal-list :capacite="selectedCapacite" />
      </Modal>
    </div>

    <div v-if="showSousCapacite" to="modals-xyz-548">
      <Modal @close="showSousCapacite = !showSousCapacite">
        <capacite-list :capacite="selectedCapacite" />
      </Modal>
    </div>
  </div>
</template>

<style scoped>
.shdow {
  box-shadow: 1px 2px 6px -2px rgba(0, 0, 0, 0.88);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
</style>

<script>
import VisionTable from "./components/VisionTable.vue";
import GoalList from "./components/GoalList.vue";
import CapaciteList from "./components/CapaciteList.vue";
import Modal from "@/components/custom/Modal.vue";
import DropDownCustom from "@/components/custom/DropDown.vue";
import GraphicView from "./components/GraphicView.vue";
import { mapGetters } from "vuex";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  name: "visionScreen",
  data() {
    return {
      showGoals: false,
      loadingData: true,
      tableItems: [],
      tableFields: [],
      modalLabel: "",
      showSousCapacite: false,
      showAddVisionModal: false,
      showEditVisionModal: false,
      showVisionModal: false,
      selectedItem: null,
      modeShow: 0,
      searchQuery: {
        name: "",
      },
      selectedCapacite: null,
    };
  },
  components: {
    VisionTable,
    Modal,
    GoalList,
    DropDownCustom,
    GraphicView,
    CapaciteList,
    CustomAddButton,
  },
  methods: {
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditVisionModal = true;
    },
    onValueChange(value) {
      this.modeShow = value;
    },
    showGoalsFunction(item) {
      this.showGoals = !this.showGoals;
      this.selectedCapacite = item;
    },
    showSousCapaciteFunction(item) {
      this.showSousCapacite = !this.showSousCapacite;
      this.selectedCapacite = item;
    },
  },
  mounted: function () {
    window.document.querySelectorAll(".ico_dm").forEach((sn_it) => {
      sn_it.addEventListener("click", (e) => {
        let tmpClasses = e.target.className.split(" ");
        if (tmpClasses.includes("icn_mod")) {
          this.newTask("Modifier la vision");
        } else if (tmpClasses.includes("icn_del")) {
          this.newTask("Supprimer la vision");
        } else if (tmpClasses.includes("icn_see")) {
          this.newTask("Fiche vision");
        }

        this.$refs.mdol.click();
      });
    });
  },
  created() {
    this.$store.dispatch("capaciteSi/fetchAllCapacites").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("capaciteSi", ["CAPACITES_SI"]),
    filteredCapacites() { 
      return this.CAPACITES_SI.filter(
        (capacite) =>
          capacite.name.toLowerCase().indexOf(this.searchQuery.name.toLowerCase()) > -1
      );
    },
  },
};
</script>
